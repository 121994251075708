import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, FormControl, Tooltip, Typography } from "@mui/material";
import "./LabForm.css";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Stack } from "@mui/system";
import FileIcon from "../../../components/FileIcon/FileIconList";
import "../../../TableStyling.css";
import "../../../App.css";
import { saveAs } from "file-saver";
import { getLabDownloadedDocument } from "../../../services/FortificationService";
import {
  DOWNLOAD,
  RANGE,
  RESULT,
  TEST_METHOD_FOLLOWED,
  TEST_NAME,
  TEST_REFERENCE,
} from "../../../textConstant";
import moment from "moment";
import useTranslatewrapper from "../../../components/useTranslateWrapper";

const getTestResultData = (item) => {
  // const labTestReferenceMethod = item.labTestReferenceMethod
  const dataObject = {
    result: { value: item?.value, ispassed: true, uom: item?.uom },
    testMethodFollowed: item?.testMethodFollowed,
    name: item?.referenceMethod,
    isMandatory:item?.isMandatory,
    selectionList: "-",
    range: "-",
  }
  // if (labTestReferenceMethod) {
    dataObject.name = item?.referenceMethod || "-";
    dataObject.selectionList = item?.testName || "-";
    const { minValue, uom, maxValue, defaultPresent } = item
    if (defaultPresent != null) {
      dataObject.range = defaultPresent;
    }
    else if (minValue != null && maxValue != null) {
      dataObject.range = `${minValue}-${maxValue} ${uom}`;
    } else if (defaultPresent == null && minValue == null && maxValue == null){
      dataObject.range = "-"
    }
    dataObject.result.ispassed = (item.testResult !== 'TEST_FAILED');
  // }
  return dataObject;
}

function LabTestDataTable(props) {
  const {translate} = useTranslatewrapper()

  const { tableData, sampleId, batchOrLotCategory, selfDeclaredTestsArr, testedLabName, testedLabTcNumber, selfDeclaredSampleTestedDate } = props;
  let fetchTestData = []

  if(selfDeclaredTestsArr?.length > 0){
    selfDeclaredTestsArr.forEach(item => (
      fetchTestData.push({
        name: item.name,
        selectionList: item.referenceMethod,
        result: { value: item?.result, ispassed: true, uom: item?.units },
        isMandatory: false,
        range: "-",
        testMethodFollowed: "-"
      })
    ))

  } else{ 
    tableData.labTests.forEach((item) => {
      // console.log(getTestResultData(item), "gettestresultdataitem")
      fetchTestData.push(getTestResultData(item));
    })
  }
  let fileArray = [];

  if(selfDeclaredTestsArr?.length > 0){
    fileArray = []
  } else{
    if (tableData?.sampleTestDocuments) {
      tableData.sampleTestDocuments.forEach((file) => {
        fileArray.push({
          name: file.name ? file.name : file.categoryDoc?.docType?.name,
          path: file.path,
        });
      });
    }
  }

  const handleFileDownload = async (path) => {
    const blob = await getLabDownloadedDocument(path, tableData.id);
    blob.data && saveAs(blob.data, path);
  };
 
  const date = tableData?.sampleSentDate ? moment.utc(tableData?.sampleSentDate).format("DD-MMM-YYYY") : selfDeclaredSampleTestedDate ? moment.utc(selfDeclaredSampleTestedDate.replace("IST", "+0530")).format("DD-MMM-YYYY") : null
  const testedDate = tableData?.testDate ? moment.utc(tableData?.testDate).format("DD-MMM-YYYY") : selfDeclaredSampleTestedDate ? moment.utc(selfDeclaredSampleTestedDate.replace("IST", "+0530")).format("DD-MMM-YYYY") : null
  return (
    <>
      <Box component={Paper} className="form-div ">
      {
        // date && (

        <Stack
          direction="row"
          alignItems="center"
          sx={{ m: 2 }}
          minWidth="100%"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <span style={{ width: "20%", display: 'flex', flexDirection: 'column'  }}>
            <span>
              <span className="inner-text-size" style={{fontWeight:"bold"}}>{translate('sampleSentDate')} : </span>
              <span className="inner-text-size">{date} </span>
            </span>
            <span>
              <span className="inner-text-size" style={{fontWeight:"bold"}}>{"Sample Tested Date"} : </span>
              <span className="inner-text-size">{testedDate} </span>
            </span>
          </span>
          <span style={{ width: "30%", display: 'flex', flexDirection: 'column' }}>
            <span>
              <span className="inner-text-size" style={{fontWeight:"bold"}}>{`${translate('labName')}: `}</span>
              <span className="inner-text-size">{tableData?.lab?.name || testedLabName || ""}</span>
            </span>
            <span>
              <span className="inner-text-size" style={{fontWeight:"bold"}}>{`${translate('tcNumber')}: `} </span>
              <span className="inner-text-size">{tableData?.lab?.certificateNo || testedLabTcNumber || ""}</span>
            </span>
          </span>

        </Stack>
        // )
      }
        {fetchTestData?.length > 0 && (
          <TableContainer className="lab-details-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate('testName')}</TableCell>
                  <TableCell align="left">{translate('testReference')}</TableCell>
                  <TableCell align="left">{translate('result')} </TableCell>
                  <TableCell align="left">{translate('range')}</TableCell>
                  <TableCell align="left">{translate('testMethodFollowed')}</TableCell>
                </TableRow>
              </TableHead>
              {fetchTestData.map((item, index) => (
                <TableBody>
                  <TableCell align="left">{item?.name }{item.isMandatory?"*":""}</TableCell>
                  <TableCell align="left">
                    <FormControl fullWidth>{item.selectionList}</FormControl>
                  </TableCell>
                  <TableCell align="left">
                    <FormControl sx={{ color: (item?.result.ispassed ? "black" : "red" )}}>
                      {item?.result.value} {((item?.result.value != undefined) && (item?.result.value != null) && (item?.result.value != "") && item?.result.uom) ? item?.result.uom : '' }
                    </FormControl>
                  </TableCell>
                   <TableCell align="left">
                    <FormControl fullWidth>{item?.range}
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    <FormControl fullWidth>
                      {item?.testMethodFollowed}
                    </FormControl>
                  </TableCell>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        )}
        <Stack
          direction="row"
          alignItems="center"
          sx={{ m: 2 }}
          minWidth="100%"
          flexWrap="wrap"
        >
          {fileArray?.length > 0 &&
            fileArray.map((item, index) => (
              <span style={{ width: "20%" }}>
                <FileIcon filename={item?.path} />
                <span className="inner-text-size">{item?.name} </span>
                <span>
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        {DOWNLOAD}
                      </Typography>
                    }
                    placement="top"
                  >
                    <Button
                      sx={{ padding: "0px", minWidth: "1px" }}
                      onClick={() => {
                        handleFileDownload(item.path);
                      }}
                    >
                      <DownloadIcon
                        sx={{ color: "grey" }}
                        fontSize="small"
                        m={1}
                      />
                    </Button>
                  </Tooltip>
                </span>
              </span>
            ))}
        </Stack>
      </Box>
    </>
  );
}

export default LabTestDataTable;
