import React, { useEffect, useState } from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import {
    TextField,
} from "@mui/material";
import { ListboxComponent } from '../../../../../components/InfiniteScroll/ListboxComponent';
import { activeLab, labList } from '../../../../../services/adminService';
import useTranslatewrapper from '../../../../../components/useTranslateWrapper';
function ManufacturerDropdown(props) {
  const { translate } = useTranslatewrapper();

    const { setManufacturerData,
        // setManufacturerName,
        manufacturerDropDownId,
        id,
        disableDropdown,
        categoryId,
        manufacturerId,
        isActiveLab,
        textFieldLabel
    } = props
    const [labName, setLabName] = useState([])
    const [listCount, setlistCount] = useState()
    const [searchString, setSearchString] = useState("");
    const setQueryparam = (value) => {
        setSearchString(value);
    };
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;
    const fetchLabNames = async (pageNumber1) => {
        var fetchdata;
        isActiveLab ?
        fetchdata = await activeLab(manufacturerId,categoryId,pageNumber1, pageSize, searchString)
        :  fetchdata = await labList(pageNumber1, pageSize, searchString)
        setPageNumber(pageNumber1);
        if (fetchdata?.data) 
        {
          setlistCount(fetchdata?.data?.count)
          const labNames = fetchdata?.data?.data?.map(
            (item) => {
              return {
                id: item.id,
                name: item.name,
              };
            }
          );
    
          setLabName((d)=> pageNumber1!==1  ? [...d, ...labNames] : labNames);
        }

    }
    useEffect(() => {
        fetchLabNames(1)
    }, [searchString]);
    return (
        <div>

{disableDropdown?


<TextField id="outlined-basic" label={textFieldLabel ? textFieldLabel : "Lab Name"} sx={{width:"100%"}} disabled={true} variant="outlined" />

            :


            <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearIcon={<ClearIcon fontSize="small" />}
                options={labName.map((option) => ({
                    label: option.name,
                    value: option.id,
                }))}
                filterOptions={options => options}
                disabled={disableDropdown|| false}
                value={
                    labName.filter(
                        (name) => name.id === manufacturerDropDownId
                    )[0]?.name
                }
                onInputChange={(e) => setQueryparam(e.target?.value)}
                onChange={(___, target) => {
                    // debugger;
                    // console.log()
                    const { value, extra, label } = target || {};
                    if(!id){
                    setManufacturerData(value);
                    // setManufacturerName(label)
                    }
                }}
                ListboxProps={{
                    style: {
                        maxHeight: "unset",
                        overflow: "unset",
                        boxShadow: "none",
                        marginBottom: 5,
                    },
                    custom: { data: labName, count: listCount },
                    fetchData: () => {
                        fetchLabNames(pageNumber + 1);
                    },
                }}
                ListboxComponent={ListboxComponent}
                onOpen={() => setQueryparam("")}
                required
                // error={submit && frkManufacturerId === "" && true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="medium"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        label={textFieldLabel || translate('labName')}
                    />
                )}
            />
                }

        </div>
    )
}

export default ManufacturerDropdown